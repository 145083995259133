import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';
import { logOut } from '@/lib/state/slices/auth-slice';
import { AppDispatch, persistor } from '@/lib/state/store';
import {
  RegisterRequest,
  RegisterResponse
} from '@/lib/types/onboarding-registration';
import posthog from 'posthog-js';

// Type Definitions
export interface LoginRequest {
  username: string;
  password: string;
}
export interface passwordResetRequest {
  email: string | null;
}

export interface passwordResetResponse {
  message: string;
}

// API Tags
export const ApiTags = [
  'AiSetupsData',
  'AiQnAData',
  'BusinessIntelligenceOverviewData',
  'ProductPerformanceSummaryData',
  'ProductPerformancePerformanceData',
  'ProductPerformanceReconsiderData',
  'ShoppingTrendsSummaryData',
  'ShoppingTrendsTrendsData',
  'ShoppingTrendsEcommerceData',
  'CustomerBehaviorsSummaryData',
  'CustomerBehaviorsBehaviorsData',
  'CustomerBehaviorsRetentionData',
  'MultiLocationAnalysisData',
  'MultiLocationLocationsData',
  'NotificationsData',
  'NotificationsUnread',
  'UnreadNotifications',
  'CalendarItems',
  'CommunicationCrmData',
  'CustomerProfileData',
  'CustomerFeedbackSummaryData',
  'CustomerFeedbackFeedbackData',
  'MarketingAppraisalSummaryData',
  'MarketingAppraisalAppraisalData',
  'MarketingAppraisalAudienceData',
  'EnhancedMarketingIntelligenceSummaryData',
  'EnhancedSegmentsAutomatedSegmentsData',
  'EnhancedMarketingGroupsCreateAGroupData',
  'EnhancedSegmentsCreateASegmentData',
  'EnhancedCampaignsAutomatedCampaignsData',
  'EnhancedCampaignsCreateACampaignData',
  'EnhancedCampaignsInitiatedCampaignsData',
  'EnhancedLandingPageData',
  'AllContactsMapsData',
  'AllContactsProfilesData',
  'AllContactUploadData',
  'EmployeeEngagementSummaryData',
  'EmployeeEngagementFeedbackData',
  'MonthlyReportsData',
  'StoreData',
  'StoreAlerts',
  'StoreUsers',
  'CampaignMetricsData',
  'ContactCommunicationPreferences',
  'DealsOfTheMonth',
  'Narratives',
  'Organizations',
  'OnboardingOrganizationData',
  'GroupedSuperInteractionsData',
  'SuperInteractionsOrdinalOptionsData',
  'ProspectManagementOpportunitiesData',
  'ProspectsManagementProspectsData',
  'ProspectManagementSummaryData',
  'smtp_data',
  'pop_data',
  'CommunicationVolumeSummaryData',
  'CommunicationVolumeVolumeData',
  'CommunicationVolumeContactsData',
  'CommunicationCrmDataAutorespond',
  'AiRecommendationsStatCards',
  'AiRecommendationsBusinessTrendsSummary',
  'AiRecommendationsCustomerLifetimeValueSummary',
  'AiRecommendationsCustomerTrendsSummary',
  'AiRecommendationsRevenueData',
  'AiRecommendationsCustomerRetentionData',
  'AiRecommendationsCustomerGrowthData',
  'AiRecommendationsSummaryBubbleChartData',
  'AiRecommendationsCustomerTrendsStackedBarChartData',
  'AiChatBotTest',
  'AiRecommendationsCustomerTrendsAccordions',
  'WebscraperProgress',
  'MarketingGroupContacts',
  'IntelligenceSummaryData'
] as const;

// Configuration
const BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;
const API_URL = `${BASE_URL}/api`;
const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL;

/**
 * Session expiration handler
 */
let isSessionExpired = false;

const handleSessionExpired = async (dispatch: AppDispatch): Promise<void> => {
  if (typeof window === 'undefined' || isSessionExpired) return;

  isSessionExpired = true;

  try {
    if (window.Echo?.connector?.pusher) {
      window.Echo.connector.pusher.unsubscribe();
      window.Echo.connector.pusher.disconnect();
      window.Echo.connector.pusher = null;
      delete window.Echo;
    }

    await persistor.purge();
    dispatch(logOut());
    localStorage.clear();
    sessionStorage.clear();

    posthog.reset();
    document.cookie.split(';').forEach((cookie) => {
      const name = cookie.split('=')[0].trim();
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=${window.location.hostname};`;
    });

    dispatch({ type: 'LOGOUT' });
    window.location.href = '/authentication/login';
  } catch (error) {
    console.error('Logout failed:', error);
    isSessionExpired = false; // Reset flag if logout fails
  }
};

// Custom base query with error handling
const baseQueryWithErrorHandling = fetchBaseQuery({
  baseUrl: API_URL,
  credentials: 'include',
  prepareHeaders: (headers) => {
    if (typeof window !== 'undefined') {
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
    }

    headers.set('Accept', 'application/json');
    headers.set('Access-Control-Allow-Origin', SITE_URL as string);

    return headers;
  }
});

const customBaseQuery = async (args: any, api: any, extraOptions: any) => {
  if (isSessionExpired) {
    return {
      error: { status: 401, data: 'Session expired' }
    };
  }

  const result = await baseQueryWithErrorHandling(args, api, extraOptions);
  if (result.error) {
    const error = result.error as FetchBaseQueryError;
    if ((error.status === 401 || error.status === 419) && !isSessionExpired) {
      await handleSessionExpired(api.dispatch);
    }
  }
  return result;
};

/**
 * RTK Query API configuration
 */
export const api = createApi({
  reducerPath: 'api',
  baseQuery: customBaseQuery,
  tagTypes: ApiTags,
  endpoints: (builder) => ({
    passwordReset: builder.mutation<
      passwordResetResponse,
      passwordResetRequest
    >({
      query: (email) => ({
        url: '/password_reset_request',
        method: 'POST',
        body: email
      })
    }),
    register: builder.mutation<RegisterResponse, RegisterRequest>({
      query: (credentials) => ({
        url: '/register',
        method: 'POST',
        body: credentials
      })
    }),
    protected: builder.mutation<{ message: string }, void>({
      query: () => 'protected'
    })
  })
});

// Export hooks for usage in components
export const {
  useProtectedMutation,
  usePasswordResetMutation,
  useRegisterMutation
} = api;

// Export the api utility
export const { util, endpoints } = api;
