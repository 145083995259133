export const USERFLOW_TOKEN = process.env.NEXT_PUBLIC_USERFLOW_TOKEN || '';

export const USER_ATTRIBUTES = {
  // Common user attributes we'll track
  EMAIL: 'email',
  NAME: 'name',
  SIGNED_UP_AT: 'signed_up_at',
  SUBSCRIPTION_PLAN: 'subscription_plan',
  BUSINESS_TYPE: 'business_type',
  LAST_LOGIN: 'last_login_at',
  TOTAL_REVENUE: 'total_revenue'
} as const;

export const EVENT_NAMES = {
  // Key events we want to track
  USER_SIGNED_UP: 'user_signed_up',
  SUBSCRIPTION_ACTIVATED: 'subscription_activated',
  CHATBOT_DEPLOYED: 'chatbot_deployed',
  CAMPAIGN_CREATED: 'campaign_created',
  FEEDBACK_COLLECTED: 'feedback_collected',
  REPORT_GENERATED: 'report_generated'
} as const;
