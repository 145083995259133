import { createSlice } from '@reduxjs/toolkit';

export interface FilterCommunicationCrmContactListState {
  filterPriorityStatus: string[];
  filterAssignedAgent: string[];
  startDate: string | null;
  endDate: string | null;
}

const initialState: FilterCommunicationCrmContactListState = {
  filterPriorityStatus: [],
  filterAssignedAgent: [],
  startDate: null,
  endDate: null
};

interface ChangeFilterStatusPayload {
  status: string;
  operation: 'add' | 'remove';
}

interface ChangeFilterAgentPayload {
  agent: string;
  operation: 'add' | 'remove';
}

interface ChangeFilterDateStartPayload {
  startDate: string | null;
}
interface ChangeFilterDateEndPayload {
  endDate: string | null;
}

const toggleInArray = (arr: string[], value: string) => {
  if (arr.includes(value)) {
    return arr.filter((item) => item !== value);
  } else {
    return [...arr, value];
  }
};

const filterCommunicationCrmContactListSlice = createSlice({
  name: 'filterCommunicationCrmContactList',
  initialState,
  reducers: {
    changeFilterStatus: (
      state,
      action: { payload: ChangeFilterStatusPayload }
    ) => {
      const { status, operation } = action.payload;
      if (typeof status === 'string' && status.trim().length > 0) {
        state.filterPriorityStatus = toggleInArray(
          state.filterPriorityStatus,
          status
        );
      }
    },
    changeFilterAgent: (
      state,
      action: { payload: ChangeFilterAgentPayload }
    ) => {
      const { agent, operation } = action.payload;
      if (typeof agent === 'string' && agent.trim().length > 0) {
        state.filterAssignedAgent = toggleInArray(
          state.filterAssignedAgent,
          agent
        );
      }
    },
    changeFilterDateStart: (
      state,
      action: { payload: ChangeFilterDateStartPayload }
    ) => {
      const { startDate } = action.payload;
      state.startDate = startDate;
    },
    changeFilterDateEnd: (
      state,
      action: { payload: ChangeFilterDateEndPayload }
    ) => {
      const { endDate } = action.payload;
      state.endDate = endDate;
    },
    resetFilterDates: (state) => {
      state.startDate = null;
      state.endDate = null;
    },

    resetFilters: (state) => {
      return initialState;
    }
  }
});

export const {
  changeFilterStatus,
  changeFilterAgent,
  changeFilterDateStart,
  changeFilterDateEnd,
  resetFilterDates,
  resetFilters
} = filterCommunicationCrmContactListSlice.actions;

export default filterCommunicationCrmContactListSlice.reducer;
