import { Logo } from '@/assets/logos/logo';
import CircularWithValueLabel from '@/components/_shared/circle-progress-with-label';
import { useAuth } from '@/lib/hooks/use-auth';
import { useGetSettingsOptimizeAccountQuery } from '@/lib/state/services/settings-optimize-account-api';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Theme, useMediaQuery } from '@mui/material';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Scrollbar } from '../../../components/_shared/scrollbar';
import { MainSidebarSection } from '@/layouts/main/main-sidebar/main-sidebar-section';
import { getSidebarSections } from '@/layouts/main/main-sidebar/main-sidebar-config';
import { BoltIcon } from '@heroicons/react/24/solid';
import { userflowService } from '@/lib/services/user-flow/user-flow-service';
interface MainSidebarComponentProps {
  onClose?: () => void;
  open?: boolean;
}
export const MainSidebarComponent: FC<React.PropsWithChildren<MainSidebarComponentProps>> = props => {
  const {
    onClose,
    open
  } = props;
  const router = useRouter();
  const {
    t
  } = useTranslation();
  const {
    user,
    logout
  } = useAuth();
  const isDesktop = userflowService.getIsDesktop();
  const sections = useMemo(() => getSidebarSections(t, router.asPath, user), [t, router.asPath, user]);
  const handlePathChange = () => {
    if (!router.isReady) {
      return;
    }
    if (open) {
      onClose?.();
    }
  };
  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
    } catch (err) {
      console.error('Logout error:', err);
      toast.error('Unable to logout.');
    }
  };
  useEffect(handlePathChange,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [router.isReady, router.asPath]);
  const {
    data: storeData
  } = useGetSettingsOptimizeAccountQuery(user?.store_id);
  const content = <>
      <Scrollbar sx={{
      height: '100%',
      '& .simplebar-content': {
        height: '100%'
      }
    }}>
        <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}>
          <div>
            <Box sx={{
            p: 3
          }}>
              <Logo variant="light" />
            </Box>
          </div>

          <Box sx={{
          flexGrow: 1,
          mt: 0
        }}>
            {sections.map(section => <MainSidebarSection key={section.title} path={router.asPath} {...section} />)}
          </Box>

          <Box sx={{
          p: 2
        }}>
            <List>
              <NextLink href="/optimize-account" passHref>
                <ListItem disablePadding>
                  <ListItemButton sx={{
                  borderRadius: 1,
                  color: 'neutral.300',
                  justifyContent: 'flex-start',
                  pr: 0,
                  textAlign: 'left',
                  textTransform: 'none',
                  width: '100%',
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255, 0.08)'
                  }
                }}>
                    <ListItemIcon>
                      {storeData && <CircularWithValueLabel storeData={storeData} />}
                    </ListItemIcon>
                    <ListItemText primary="Optimize Account" />
                  </ListItemButton>
                </ListItem>
              </NextLink>

              {/* Only show Setup Tutorial button on desktop where Userflow is enabled */}
              {isDesktop && <NextLink href="/crm/communication-crm?setup-tutorial" passHref>
                  <ListItem disablePadding>
                    <ListItemButton sx={{
                  borderRadius: 1,
                  color: 'neutral.300',
                  justifyContent: 'flex-start',
                  pr: 0,
                  textAlign: 'left',
                  textTransform: 'none',
                  width: '100%',
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255, 0.08)'
                  }
                }}>
                      <ListItemIcon>
                        <BoltIcon className="w-5 h-5" />
                      </ListItemIcon>
                      <ListItemText primary="Setup Tutorial" />
                    </ListItemButton>
                  </ListItem>
                </NextLink>}

              <ListItem disablePadding onClick={e => {
              e.preventDefault();
              handleLogout();
            }}>
                <ListItemButton sx={{
                borderRadius: 1,
                color: 'neutral.300',
                justifyContent: 'flex-start',
                pr: 0,
                textAlign: 'left',
                textTransform: 'none',
                width: '100%',
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255, 0.05)'
                }
              }}>
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItemButton>
              </ListItem>
              <div className="mt-6 text-xs text-zinc-400">
                &copy; {new Date().getFullYear()}. All rights reserved.
                <NextLink href="https://ripemetrics.com/legal/terms-of-service" passHref className="px-1 font-medium text-zinc-600 hover:text-orange-500 hover:underline" target="_blank">
                  Terms of Service
                </NextLink>
              </div>
            </List>
          </Box>
        </Box>
      </Scrollbar>
    </>;
  const xlUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));
  if (xlUp) {
    return <Drawer anchor="left" open PaperProps={{
      sx: {
        backgroundColor: 'neutral.900',
        borderRightColor: 'divider',
        borderRightStyle: 'solid',
        borderRightWidth: theme => theme.palette.mode === 'dark' ? 1 : 0,
        color: '#FFFFFF',
        width: 280,
        zIndex: theme => theme.zIndex.appBar + 100
      }
    }} variant="permanent">
        {content}
      </Drawer>;
  }
  return <Drawer anchor="left" onClose={onClose} open={open} PaperProps={{
    sx: {
      backgroundColor: 'neutral.900',
      color: '#FFFFFF',
      width: 280
    }
  }} sx={{
    zIndex: theme => theme.zIndex.appBar + 100
  }} variant="temporary" data-sentry-element="Drawer" data-sentry-component="MainSidebarComponent" data-sentry-source-file="main-sidebar-component.tsx">
      {content}
    </Drawer>;
};
MainSidebarComponent.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};