import { useAuth } from '@/lib/hooks/use-auth';
import { createContext, useContext, useEffect, useMemo } from 'react';
import userflow from 'userflow.js';
import { userflowService } from '@/lib/services/user-flow/user-flow-service';
interface UserflowContextValue {
  identify: (userId: string, attributes?: Record<string, any>) => void;
  track: (eventName: string, attributes?: Record<string, any>) => void;
  updateUser: (attributes: Record<string, any>) => void;
  reset: () => void;
  isDesktop: boolean;
}
const UserflowContext = createContext<UserflowContextValue | null>(null);
export interface UserflowProviderProps {
  children: React.ReactNode;
  token: string;
}
export function UserflowProvider({
  children,
  token
}: UserflowProviderProps) {
  const {
    user
  } = useAuth();
  const isDesktop = userflowService.getIsDesktop();
  useEffect(() => {
    // Only initialize Userflow on desktop
    if (token && isDesktop) {
      try {
        console.log('Initializing Userflow with token:', token);
        userflow.init(token);
        console.log('Userflow initialized successfully');
      } catch (error) {
        console.error('Failed to initialize Userflow:', error);
      }
    }
  }, [token, isDesktop]);
  useEffect(() => {
    // Only identify user on desktop
    if (user && isDesktop) {
      try {
        userflow.identify(user.id, {
          name: user.name,
          email: user.email,
          signed_up_at: user.createdAt
          // Add any other user attributes you want to track
        });
      } catch (error) {
        console.error('Failed to identify user:', error);
      }
    } else if (isDesktop) {
      userflow.reset();
    }
    return () => {
      if (isDesktop) {
        userflow.reset();
      }
    };
  }, [user, isDesktop]);
  const value = useMemo(() => ({
    identify: (userId: string, attributes?: Record<string, any>) => {
      if (isDesktop) userflow.identify(userId, attributes);
    },
    track: (eventName: string, attributes?: Record<string, any>) => {
      if (isDesktop) userflow.track(eventName, attributes);
    },
    updateUser: (attributes: Record<string, any>) => {
      if (isDesktop) userflow.updateUser(attributes);
    },
    reset: () => {
      if (isDesktop) userflow.reset();
    },
    isDesktop
  }), [isDesktop]);
  return <UserflowContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="UserflowProvider" data-sentry-source-file="user-flow-context.tsx">
      {children}
    </UserflowContext.Provider>;
}
export const useUserflowContext = () => {
  const context = useContext(UserflowContext);
  if (!context) {
    throw new Error('useUserflowContext must be used within a UserflowProvider');
  }
  return context;
};