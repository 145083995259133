import { HorizonLogoIcon } from '@/assets/icons/horizon-logo-icon';
import { LaravelPulseLogo } from '@/assets/icons/laravel-pulse-logo';
import UserbackUserPopoverSuggestion from '@/components/_shared/userback/userback-sidebar-suggestion';
import { useInitials } from '@/lib/hooks/use-initials';
import { usePhoneNumberFormatter } from '@/lib/hooks/usePhoneNumberFormatter';
import { useAppDispatch, useAppSelector } from '@/lib/state/hooks';
import { useGetStoreSettingsQuery } from '@/lib/state/services/customer-settings-api';
import { UserInterface } from '@/lib/types/user-interface';
import { ArrowRightStartOnRectangleIcon, AtSymbolIcon, Cog6ToothIcon, DevicePhoneMobileIcon, GlobeAltIcon, SparklesIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { BoltIcon } from '@heroicons/react/24/outline';
import { Box, Divider, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import * as React from 'react';
import toast from 'react-hot-toast';
import { useAuth } from '@/lib/hooks/use-auth';
import WelcomeModalComponent from '@/components/onboarding/welcome-modal/welcome-modal-component';
interface UserPopoverProps {
  anchorEl: null | Element;
  onClose: () => void;
  open?: boolean;
}
export const UserPopover: FC<React.PropsWithChildren<UserPopoverProps>> = props => {
  const {
    anchorEl,
    onClose,
    open,
    ...other
  } = props;
  const router = useRouter();
  const {
    logout
  } = useAuth();
  const user = useAppSelector(state => state.auth.user) as UserInterface;
  const {
    data: storeSettings
  } = useGetStoreSettingsQuery();
  const isRipeMetrics = user?.permissions?.is_ripemetrics ?? false;
  const sanctumBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;

  //use getStore query to get store data

  const handleLogout = async (): Promise<void> => {
    try {
      onClose();
      await logout();
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  // initialsFromName takes a string from the variable
  // user.user.user.name and returns a string containing
  // the initials of the name

  //   const name = user.user.user.name?? 'User';
  const userName = user?.name as string;
  const getInitials = useInitials(userName);
  const [openModal, setOpenModal] = React.useState(false);
  const welcomeValue = router.query.welcome;
  React.useEffect(() => {
    if (welcomeValue === 'true') {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [welcomeValue]);
  const formatPhoneNumber = usePhoneNumberFormatter();
  const dispatch = useAppDispatch();
  return open ? <Popover anchorEl={anchorEl} anchorOrigin={{
    horizontal: 'center',
    vertical: 'bottom'
  }} keepMounted={false} onClose={onClose} open={!!open} slotProps={{
    paper: {
      sx: {
        width: 275
      }
    }
  }} transitionDuration={0} {...other} data-sentry-element="Popover" data-sentry-component="UserPopover" data-sentry-source-file="user-popover.tsx">
      <Box sx={{
      alignItems: 'center',
      p: 2,
      display: 'flex'
    }} data-sentry-element="Box" data-sentry-source-file="user-popover.tsx">
        <span className={`inline-flex h-10 w-10 items-center justify-center rounded-full bg-orange-100 text-orange-500 `}>
          <span className="leading-none font-base font-proximaSemiBold">
            {getInitials}
          </span>
        </span>
        <Box sx={{
        ml: 1
      }} data-sentry-element="Box" data-sentry-source-file="user-popover.tsx">
          <Typography variant="body1" data-sentry-element="Typography" data-sentry-source-file="user-popover.tsx">{userName ?? 'username'}</Typography>
        </Box>
      </Box>
      <div className="px-4 space-y-2 text-sm">
        {storeSettings?.data?.twilio_number && <div className="flex items-center">
            <DevicePhoneMobileIcon className="w-5 h-5 text-gray-600" />:
            <span className="pl-3">
              {formatPhoneNumber(storeSettings?.data?.twilio_number)}
            </span>
          </div>}
        {storeSettings?.data?.email_branded && <div className="flex flex-wrap items-center w-full">
            <AtSymbolIcon className="w-5 h-5 text-gray-600" />:
            <span className="pl-3 truncate">
              {storeSettings?.data?.email_branded}
            </span>
          </div>}
        {storeSettings?.data?.subdomain && <div className="flex flex-wrap items-center w-full pb-2">
            <GlobeAltIcon className="w-5 h-5 text-gray-600" />:
            <a className="pl-3 text-blue-500 underline truncate hover:text-blue-700" href={'https://' + storeSettings?.data?.subdomain} target="_blank" rel="noreferrer">
              {storeSettings?.data?.subdomain}
            </a>
          </div>}
      </div>
      <Divider data-sentry-element="Divider" data-sentry-source-file="user-popover.tsx" />
      <Box sx={{
      my: 1
    }} data-sentry-element="Box" data-sentry-source-file="user-popover.tsx">
        <NextLink href="/user-profile" passHref onClick={onClose} data-sentry-element="NextLink" data-sentry-source-file="user-popover.tsx">
          <MenuItem className="group" data-sentry-element="MenuItem" data-sentry-source-file="user-popover.tsx">
            <ListItemIcon data-sentry-element="ListItemIcon" data-sentry-source-file="user-popover.tsx">
              <div className="text-sm text-gray-600 group-hover:text-orange-500">
                <UserCircleIcon className="w-5 h-5" data-sentry-element="UserCircleIcon" data-sentry-source-file="user-popover.tsx" />
              </div>
            </ListItemIcon>

            <ListItemText primary={<p className="text-sm group-hover:text-orange-500">
                  User Profile
                </p>} data-sentry-element="ListItemText" data-sentry-source-file="user-popover.tsx" />
          </MenuItem>
        </NextLink>

        <NextLink href="/settings/location/profile/address" passHref onClick={onClose} data-sentry-element="NextLink" data-sentry-source-file="user-popover.tsx">
          <MenuItem className="group" data-sentry-element="MenuItem" data-sentry-source-file="user-popover.tsx">
            <ListItemIcon data-sentry-element="ListItemIcon" data-sentry-source-file="user-popover.tsx">
              <div className="text-gray-600 group-hover:text-orange-500">
                <Cog6ToothIcon className="w-5 h-5" data-sentry-element="Cog6ToothIcon" data-sentry-source-file="user-popover.tsx" />
              </div>
            </ListItemIcon>
            <ListItemText primary={<p className="text-sm group-hover:text-orange-500">Settings</p>} data-sentry-element="ListItemText" data-sentry-source-file="user-popover.tsx" />
          </MenuItem>
        </NextLink>

        <NextLink href="/optimize-account" passHref onClick={onClose} data-sentry-element="NextLink" data-sentry-source-file="user-popover.tsx">
          <MenuItem className="group" data-sentry-element="MenuItem" data-sentry-source-file="user-popover.tsx">
            <ListItemIcon data-sentry-element="ListItemIcon" data-sentry-source-file="user-popover.tsx">
              <div className="text-gray-600 group-hover:text-orange-500">
                <BoltIcon className="w-5 h-5" data-sentry-element="BoltIcon" data-sentry-source-file="user-popover.tsx" />
              </div>
            </ListItemIcon>
            <ListItemText primary={<p className="text-sm group-hover:text-orange-500">
                  Optimize Account
                </p>} data-sentry-element="ListItemText" data-sentry-source-file="user-popover.tsx" />
          </MenuItem>
        </NextLink>
        <MenuItem className="group" onClick={() => {
        setOpenModal(true);
      }} data-sentry-element="MenuItem" data-sentry-source-file="user-popover.tsx">
          <ListItemIcon data-sentry-element="ListItemIcon" data-sentry-source-file="user-popover.tsx">
            <div className="text-gray-600 group-hover:text-orange-500">
              <SparklesIcon className="w-5 h-5" data-sentry-element="SparklesIcon" data-sentry-source-file="user-popover.tsx" />
            </div>
          </ListItemIcon>
          <ListItemText primary={<p className="text-sm group-hover:text-orange-500">
                Welcome Guide
              </p>} data-sentry-element="ListItemText" data-sentry-source-file="user-popover.tsx" />
        </MenuItem>
        <UserbackUserPopoverSuggestion onClose={onClose} data-sentry-element="UserbackUserPopoverSuggestion" data-sentry-source-file="user-popover.tsx" />

        {isRipeMetrics && <>
            <Divider />
            {/* TODO:IS NOT WORKING */}
            {/* <NextLink
              href={`${sanctumBaseUrl}/log-viewer`}
              target="_blank"
              passHref
              onClick={onClose}
             >
              <MenuItem className="group">
                <ListItemIcon>
                  <div className="text-gray-600 group-hover:text-orange-500">
                    <ChartSquareBarIcon className="w-5 h-5" />
                  </div>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      className=" group-hover:text-orange-500"
                    >
                      Log Viewer
                    </Typography>
                  }
                />
              </MenuItem>
             </NextLink> */}
            <NextLink href={`${sanctumBaseUrl}/horizon/dashboard`} target="_blank" passHref onClick={onClose}>
              <MenuItem className="group">
                <ListItemIcon className="pl-0.5">
                  <div className="text-gray-900 group-hover:text-orange-500">
                    <HorizonLogoIcon className="w-4 h-4" />
                  </div>
                </ListItemIcon>
                <ListItemText primary={<Typography variant="body1" className=" group-hover:text-orange-500">
                      Horizon Logs
                    </Typography>} />
              </MenuItem>
            </NextLink>
            <NextLink href={`${sanctumBaseUrl}/pulse`} target="_blank" passHref onClick={onClose}>
              <MenuItem className="group">
                <ListItemIcon>
                  <div className="text-gray-900 group-hover:text-orange-500">
                    <LaravelPulseLogo className="w-5 h-5" />
                  </div>
                </ListItemIcon>
                <ListItemText primary={<Typography variant="body1" className=" group-hover:text-orange-500">
                      Laravel Pulse
                    </Typography>} />
              </MenuItem>
            </NextLink>
          </>}

        <Divider data-sentry-element="Divider" data-sentry-source-file="user-popover.tsx" />

        <MenuItem className="group" onClick={handleLogout} data-sentry-element="MenuItem" data-sentry-source-file="user-popover.tsx">
          <ListItemIcon data-sentry-element="ListItemIcon" data-sentry-source-file="user-popover.tsx">
            <div className="text-gray-600 group-hover:text-orange-500">
              <ArrowRightStartOnRectangleIcon className="w-5 h-5" data-sentry-element="ArrowRightStartOnRectangleIcon" data-sentry-source-file="user-popover.tsx" />
            </div>
          </ListItemIcon>
          <ListItemText primary={<p className="text-sm group-hover:text-orange-500">Sign out</p>} data-sentry-element="ListItemText" data-sentry-source-file="user-popover.tsx" />
        </MenuItem>
      </Box>
      <WelcomeModalComponent closePopover={onClose} openModal={openModal} setOpenModal={setOpenModal} data-sentry-element="WelcomeModalComponent" data-sentry-source-file="user-popover.tsx" />
    </Popover> : null;
};