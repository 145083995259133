import type { UserflowUser, UserflowEvent } from '@/lib/types/user-flow';
import {
  USERFLOW_TOKEN,
  USER_ATTRIBUTES,
  EVENT_NAMES
} from '@/lib/utils/user-flow-config';
import userflow from 'userflow.js';

class UserflowService {
  private initialized = false;
  private isDesktop = false;

  constructor() {
    if (typeof window !== 'undefined') {
      // Check if desktop and initialize only if it is
      this.checkIfDesktop();
      window.addEventListener('resize', this.checkIfDesktop);

      // Only initialize if on desktop
      if (this.isDesktop) {
        this.init();
      }
    }
  }

  /**
   * Checks if the current device is desktop based on screen width
   */
  private checkIfDesktop = () => {
    if (typeof window !== 'undefined') {
      const mobileBreakpoint = 768;
      const wasDesktop = this.isDesktop;
      this.isDesktop = window.innerWidth >= mobileBreakpoint;

      // If transitioning from mobile to desktop, initialize
      if (!wasDesktop && this.isDesktop && !this.initialized) {
        this.init();
      }

      // If transitioning from desktop to mobile, reset
      if (wasDesktop && !this.isDesktop && this.initialized) {
        this.reset();
      }
    }
  };

  /**
   * Initialize Userflow with the token
   */
  private init() {
    if (!this.initialized && USERFLOW_TOKEN && this.isDesktop) {
      try {
        userflow.init(USERFLOW_TOKEN);
        this.initialized = true;
        console.log('Userflow initialized successfully');
      } catch (error) {
        console.error('Failed to initialize Userflow:', error);
      }
    }
  }

  /**
   * Identify a user in Userflow
   */
  identifyUser(user: UserflowUser) {
    if (!this.initialized || !this.isDesktop) return;

    try {
      const attributes = {
        [USER_ATTRIBUTES.EMAIL]: user.email,
        [USER_ATTRIBUTES.NAME]: user.name,
        [USER_ATTRIBUTES.SUBSCRIPTION_PLAN]: user.subscriptionPlan,
        [USER_ATTRIBUTES.BUSINESS_TYPE]: user.businessType,
        [USER_ATTRIBUTES.SIGNED_UP_AT]: user.signedUpAt,
        [USER_ATTRIBUTES.TOTAL_REVENUE]: user.totalRevenue
      };

      userflow.identify(user.id, attributes);
    } catch (error) {
      console.error('Failed to identify user in Userflow:', error);
    }
  }

  /**
   * Track an event in Userflow
   */
  trackEvent(event: UserflowEvent) {
    if (!this.initialized || !this.isDesktop) return;

    try {
      userflow.track(event.name, event.attributes);
    } catch (error) {
      console.error(`Failed to track event ${event.name}:`, error);
    }
  }

  /**
   * Update user attributes in Userflow
   */
  updateUser(attributes: Partial<Record<keyof typeof USER_ATTRIBUTES, any>>) {
    if (!this.initialized || !this.isDesktop) return;

    try {
      userflow.updateUser(attributes);
    } catch (error) {
      console.error('Failed to update user in Userflow:', error);
    }
  }

  /**
   * Reset Userflow
   */
  reset() {
    if (!this.initialized || !this.isDesktop) return;

    try {
      userflow.reset();
    } catch (error) {
      console.error('Failed to reset Userflow:', error);
    }
  }

  /**
   * Check if the current device is desktop
   */
  getIsDesktop() {
    return this.isDesktop;
  }

  // Custom methods for RipeMetrics-specific events
  trackSubscriptionActivated(plan: string, price: number) {
    this.trackEvent({
      name: EVENT_NAMES.SUBSCRIPTION_ACTIVATED,
      attributes: {
        plan_name: plan,
        plan_price: price
      }
    });
  }

  trackChatbotDeployed(businessName: string, templateUsed: string) {
    this.trackEvent({
      name: EVENT_NAMES.CHATBOT_DEPLOYED,
      attributes: {
        business_name: businessName,
        template: templateUsed
      }
    });
  }
}

export const userflowService = new UserflowService();
