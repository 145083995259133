import type { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { FC, ReactElement, ReactNode, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/nextjs';
import { UserbackProvider } from '@userback/react';
import { Analytics } from '@vercel/analytics/react';
import { PostHogProvider } from 'posthog-js/react';
import { DarkModeConsumer, DarkModeProvider } from '@/lib/contexts/dark-mode-context';
import { persistor, store } from '@/lib/state/store';
import { useTheme } from '@/lib/theme';
import { createEmotionCache } from '@/lib/utils/create-emotion-cache';
import '@/lib/i18n/i18n';
import '@/lib/styles/global.css';
import { setupAxios } from '@/lib/utils/axios-config';
import { setupNProgress } from '@/lib/utils/nprogress';
import { initializePostHog, usePostHogPageView } from '@/lib/utils/posthog';
import Maintenance from '@/pages/maintenance';
import { useRouter } from 'next/router';
import ErrorBoundary from '@/components/_shared/error-boundary/error-boundry';
import ErrorBoundaryFallback from '@/components/_shared/error-boundary/Error';
import { PersistentLayout } from '@/layouts/persistent-layout';
import { AuthProvider } from '@/lib/contexts/auth-context';
import { usePrioritizedServices } from '@/lib/hooks/use-service-loader';
import PusherInit from '@/lib/services/pusher-initialize/pusher-initialize';
import TailwindIndicator from '@/lib/utils/tailwind-indicator';
import { LoadingProvider } from '@/lib/contexts/loading-context';
import { UserflowProvider } from '@/lib/contexts/user-flow-context';
import { userflowService } from '@/lib/services/user-flow/user-flow-service';
export type GetLayoutFn = (page: ReactElement) => ReactElement;
type NextPageWithLayout = NextPage & {
  getLayout?: GetLayoutFn;
};
type EnhancedAppProps = AppProps & {
  Component: NextPageWithLayout;
  emotionCache: EmotionCache;
};
interface DarkMode {
  responsiveFontSizes?: boolean;
  theme: 'light' | 'dark';
}
interface ThemeWrapperProps {
  darkMode: DarkMode;
  children: ReactNode;
}
interface DesktopOnlyUserflowProps {
  children: ReactNode;
  token: string;
}

// Component that only renders UserflowProvider on desktop devices
const DesktopOnlyUserflow: FC<DesktopOnlyUserflowProps> = ({
  children,
  token
}) => {
  const [isClient, setIsClient] = useState(false);

  // Use isDesktop from the userflowService for consistency
  const isDesktop = isClient ? userflowService.getIsDesktop() : false;
  useEffect(() => {
    setIsClient(true);
  }, []);

  // During SSR or on mobile, just render children without wrapping
  if (!isClient || !isDesktop) {
    return <>{children}</>;
  }

  // On desktop, wrap with UserflowProvider
  return <UserflowProvider token={token} data-sentry-element="UserflowProvider" data-sentry-component="DesktopOnlyUserflow" data-sentry-source-file="_app.tsx">{children}</UserflowProvider>;
};
const clientSideEmotionCache = createEmotionCache();
setupNProgress();
setupAxios();
const App: FC<EnhancedAppProps> = props => {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    ...rest
  } = props;
  const router = useRouter();
  usePostHogPageView(router);

  // Use prioritized service loading
  const services = usePrioritizedServices();

  // Log errors in development
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      if (services.errors.critical) {
        console.error('Critical service errors:', services.errors.critical);
      }
      if (services.errors.important) {
        console.warn('Important service errors:', services.errors.important);
      }
      if (services.errors.nonCritical) {
        console.info('Non-critical service errors:', services.errors.nonCritical);
      }
    }
  }, [services.errors]);
  const getLayout = Component.getLayout ?? undefined;
  const maintenance: boolean | undefined = false;
  if (maintenance) {
    return <Maintenance />;
  }
  const ThemeWrapper: FC<ThemeWrapperProps> = ({
    darkMode,
    children
  }) => {
    const theme = useTheme({
      responsiveFontSizes: darkMode.responsiveFontSizes ?? false,
      mode: darkMode.theme
    });
    return <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-component="ThemeWrapper" data-sentry-source-file="_app.tsx">{children}</ThemeProvider>;
  };
  return <Sentry.ErrorBoundary fallback={({
    error
  }) => <ErrorBoundaryFallback error={error} />} data-sentry-element="unknown" data-sentry-component="App" data-sentry-source-file="_app.tsx">
      <CacheProvider value={emotionCache} data-sentry-element="CacheProvider" data-sentry-source-file="_app.tsx">
        <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
          <title>RipeMetrics</title>
          <meta name="viewport" content="initial-scale=1, width=device-width" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        </Head>

        <ReduxProvider store={store} data-sentry-element="ReduxProvider" data-sentry-source-file="_app.tsx">
          <PersistGate loading={null} persistor={persistor} data-sentry-element="PersistGate" data-sentry-source-file="_app.tsx">
            <LocalizationProvider dateAdapter={AdapterDateFns} data-sentry-element="LocalizationProvider" data-sentry-source-file="_app.tsx">
              <AuthProvider data-sentry-element="AuthProvider" data-sentry-source-file="_app.tsx">
                <LoadingProvider data-sentry-element="LoadingProvider" data-sentry-source-file="_app.tsx">
                  <DarkModeProvider data-sentry-element="DarkModeProvider" data-sentry-source-file="_app.tsx">
                    <DarkModeConsumer data-sentry-element="DarkModeConsumer" data-sentry-source-file="_app.tsx">
                      {({
                      darkMode
                    }) => <ThemeWrapper darkMode={darkMode}>
                          <PostHogProvider client={initializePostHog()}>
                            <DesktopOnlyUserflow token={process.env.NEXT_PUBLIC_USERFLOW_TOKEN || ''}>
                              <CssBaseline />
                              <Toaster position="top-right" reverseOrder={true} />
                              <PusherInit />
                              <UserbackProvider token={process.env.NEXT_PUBLIC_USERBACK_TOKEN as string}>
                                <TailwindIndicator />
                                <PersistentLayout getLayout={getLayout}>
                                  <ErrorBoundary>
                                    <Component {...rest} />
                                  </ErrorBoundary>
                                </PersistentLayout>
                              </UserbackProvider>
                              <Analytics />
                            </DesktopOnlyUserflow>
                          </PostHogProvider>
                        </ThemeWrapper>}
                    </DarkModeConsumer>
                  </DarkModeProvider>
                </LoadingProvider>
              </AuthProvider>
            </LocalizationProvider>
          </PersistGate>
        </ReduxProvider>
      </CacheProvider>
    </Sentry.ErrorBoundary>;
};
export default App;