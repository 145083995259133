import { createSlice } from '@reduxjs/toolkit';

export type TimeframeStatus = 'seven_day' | 'four_week' | 'six_month';
export interface TimeframeState {
  id: 0 | 1 | 2 | 3;
  value: number;
  status: TimeframeStatus;
}

const initialState: TimeframeState = {
  id: 0,
  value: 1,
  status: 'seven_day'
};

const timeframeSlice = createSlice({
  name: 'timeframe',
  initialState,
  reducers: {
    changeTimeframe: (state, action) => {
      if (action.payload === 1) {
        state.id = 0;
        state.value = 1;
        state.status = 'seven_day';
      } else if (action.payload === 2) {
        state.id = 1;
        state.value = 2;
        state.status = 'four_week';
      } else if (action.payload === 3) {
        state.id = 2;
        state.value = 3;
        state.status = 'six_month';
      } else {
        state.id = 2;
        state.value = 3;
        state.status = 'six_month';
      }

      state.value = action.payload;
    }
  }
});

export const { changeTimeframe } = timeframeSlice.actions;

export default timeframeSlice.reducer;
