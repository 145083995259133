'use client';

import React, { Component, type ReactNode } from 'react';
import { AlertCircle, RefreshCcw, Trash2, MessageCircle, ArrowLeft, Home, Lightbulb } from 'lucide-react';
import { useAuth } from '@/lib/hooks/use-auth';

// Try to import external error reporting tools, but don't fail if they're not available
let reportReactError: (error: Error, errorInfo: React.ErrorInfo, options?: {
  metadata?: Record<string, any>;
}) => void;
try {
  const flare = require('@flareapp/flare-react');
  reportReactError = flare.reportReactError;
} catch (e) {
  // If Flare isn't installed, create a placeholder function
  reportReactError = () => console.warn('Flare not installed. Error not reported.');
}

// For Userback integration
let userback;
try {
  const userbackModule = require('@userback/react');
  userback = userbackModule.useUserback;
} catch (e) {
  // Create a placeholder if Userback isn't available
  userback = () => ({
    open: () => console.warn('Userback not installed. Unable to open feedback form.'),
    identify: () => console.warn('Userback not installed. Unable to identify user.'),
    setData: () => console.warn('Userback not installed. Unable to set data.')
  });
}
interface Props {
  children: ReactNode;
  fallback?: ReactNode | ((props: {
    error?: Error;
    errorInfo?: React.ErrorInfo;
    resetErrorBoundary: () => void;
  }) => ReactNode);
  resetOnNavigation?: boolean;
  onError?: (error: Error, errorInfo: React.ErrorInfo) => void;
  onReset?: () => void;
  navigationKey?: string;
}
interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: React.ErrorInfo;
}
interface TroubleshootingStep {
  name: string;
  description: string;
  icon: React.FC<{
    className?: string;
  }>;
  iconColor: string;
  action: () => void;
}

// Separate functional component for the error UI
const ErrorUI = ({
  error,
  errorInfo,
  resetErrorBoundary
}: {
  error?: Error;
  errorInfo?: React.ErrorInfo;
  resetErrorBoundary: () => void;
}) => {
  const [feedbackSent, setFeedbackSent] = React.useState(false);
  const {
    user
  } = useAuth();

  // Try to load user data if available
  React.useEffect(() => {
    const loadUserData = async () => {
      try {
        // Try to dynamically import auth hook
        const authModule = await import('@/lib/hooks/use-auth');
        const {
          useAuth
        } = authModule;
        // Need to check if we're in a component context before using hooks
        if (typeof window !== 'undefined') {
          // This is a workaround since we can't directly use hooks here
          // In a real implementation, you'd want to pass user data as props instead
          const authData = JSON.parse(localStorage.getItem('auth') || '{}');
        }
      } catch (e) {
        console.warn('Could not load user data for error reporting');
      }
    };
    loadUserData();
  }, []);

  // Function to open Userback for feedback
  const openUserbackFeedback = () => {
    try {
      // Get current instance of Userback (wrap in try/catch in case it's not initialized)
      // @ts-ignore - Userback should be available on window in the browser
      const userbackInstance = window.Userback || null;
      if (userbackInstance) {
        // Identify the user if we have user data
        if (user?.id && user?.email) {
          userbackInstance.identify(user.id.toString(), {
            name: user.name || '',
            email: user.email || '',
            account_id: user.store_id || ''
          });
        }

        // Set additional error context data
        userbackInstance.setData({
          error_message: error?.message,
          error_stack: error?.stack,
          component_stack: errorInfo?.componentStack,
          url: window.location.href,
          timestamp: new Date().toISOString()
        });

        // Open the Userback widget
        userbackInstance.open('bug', 'form');
        setFeedbackSent(true);
      } else {
        console.error('Userback not available on the window object');
      }
    } catch (e) {
      console.error('Failed to open Userback:', e);
    }
  };
  const troubleshootingSteps: TroubleshootingStep[] = [{
    name: 'Refresh the page',
    description: 'Reload your current session to resolve temporary issues',
    icon: RefreshCcw,
    iconColor: 'bg-orange-500',
    action: () => window.location.reload()
  }, {
    name: 'Clear browser data',
    description: 'Clear cached data that might be causing conflicts',
    icon: Trash2,
    iconColor: 'bg-amber-500',
    action: () => {
      if (window.confirm('This will clear your local storage and session data for this site. Continue?')) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      }
    }
  }, {
    name: 'Go back',
    description: 'Return to the previous page you were viewing',
    icon: ArrowLeft,
    iconColor: 'bg-indigo-500',
    action: () => window.history.back()
  }, {
    name: 'Go to homepage',
    description: 'Return to the main page of the application',
    icon: Home,
    iconColor: 'bg-green-500',
    action: () => window.location.href = '/'
  }];
  return <div className="min-h-[50vh] flex items-center justify-center p-4 bg-gray-50" data-sentry-component="ErrorUI" data-sentry-source-file="error-boundry.tsx">
      <div className="w-full max-w-md overflow-hidden bg-white shadow-lg rounded-xl">
        <div className="p-6 border-b border-gray-200 bg-red-50">
          <div className="flex items-center gap-3">
            <div className="shrink-0">
              <AlertCircle className="w-8 h-8 text-red-500" data-sentry-element="AlertCircle" data-sentry-source-file="error-boundry.tsx" />
            </div>
            <div>
              <h2 className="text-xl font-bold text-gray-900">
                Something went wrong
              </h2>
              <p className="text-sm text-gray-600">
                We&apos;ve encountered an unexpected error
              </p>
            </div>
          </div>
        </div>

        <div className="p-6">
          <h3 className="mb-4 font-medium text-gray-900">
            Try these troubleshooting steps:
          </h3>

          <ul className="mb-6 space-y-3">
            {troubleshootingSteps.map((step, idx) => <li key={idx}>
                <button onClick={step.action} className="flex items-center w-full p-3 text-left transition-colors rounded-lg hover:bg-gray-50 group">
                  <div className={`shrink-0 ${step.iconColor} rounded-lg p-2 mr-3`}>
                    <step.icon className="w-5 h-5 text-white" />
                  </div>
                  <div>
                    <div className="font-medium text-gray-900 group-hover:text-gray-700">
                      {step.name}
                    </div>
                    <p className="text-sm text-gray-500">{step.description}</p>
                  </div>
                </button>
              </li>)}
          </ul>

          {!feedbackSent ? <div className="pt-4 mt-6 border-t border-gray-200">
              <h3 className="flex items-center gap-2 mb-2 font-medium text-gray-900">
                <MessageCircle className="w-5 h-5 text-gray-500" />
                Report this issue
              </h3>
              <div className="mb-4 text-sm text-gray-600">
                Help us improve by reporting this error. We&apos;ll collect the
                error details and your feedback to fix the issue.
              </div>
              <button type="button" onClick={openUserbackFeedback} className="flex items-center justify-center w-full px-4 py-2 font-medium text-white transition-colors bg-orange-600 rounded-lg hover:bg-orange-700">
                <Lightbulb className="w-5 h-5 mr-2" />
                Submit Feedback
              </button>
            </div> : <div className="pt-4 mt-6 border-t border-gray-200">
              <div className="p-4 rounded-lg bg-green-50">
                <div className="flex">
                  <div className="shrink-0">
                    <svg className="w-5 h-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-green-800">
                      Feedback form opened
                    </h3>
                    <div className="mt-2 text-sm text-green-700">
                      <p>
                        Thank you for helping us improve. Please complete the
                        feedback form to submit details about this error.
                      </p>
                    </div>
                    <div className="mt-4">
                      <div className="-mx-2 -my-1.5 flex">
                        <button onClick={resetErrorBoundary} className="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                          Try again
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>}

          {process.env.NODE_ENV === 'development' && <div className="pt-4 mt-6 border-t border-gray-200">
              <details className="text-sm">
                <summary className="font-medium text-gray-900 cursor-pointer">
                  Error details (development only)
                </summary>
                <div className="mt-2 p-3 bg-gray-100 rounded-lg overflow-auto max-h-[200px]">
                  <p className="font-mono text-red-600">{error?.message}</p>
                  <pre className="mt-2 text-xs text-gray-700 whitespace-pre-wrap">
                    {error?.stack}
                  </pre>
                  {errorInfo && <>
                      <p className="mt-4 font-medium">Component Stack:</p>
                      <pre className="text-xs text-gray-700 whitespace-pre-wrap">
                        {errorInfo.componentStack}
                      </pre>
                    </>}
                </div>
              </details>
            </div>}
        </div>
      </div>
    </div>;
};
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false
    };
    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
  }
  static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error
    };
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({
      errorInfo
    });

    // Log error to console in development
    if (process.env.NODE_ENV === 'development') {
      console.error('Error caught by ErrorBoundary:', error);
      console.error('Component stack:', errorInfo.componentStack);
    }

    // Call onError callback if provided
    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    }

    // Report to error monitoring service
    try {
      // Try to report with Flare if available
      reportReactError(error, errorInfo, {
        // Add additional context for better debugging
        metadata: {
          url: window.location.href,
          userAgent: navigator.userAgent,
          timestamp: new Date().toISOString(),
          environment: process.env.NODE_ENV || 'unknown'
        }
      });

      // Pre-configure Userback data for when the user opens it
      try {
        // @ts-ignore - Userback should be available on window in the browser
        const userbackInstance = window.Userback;
        if (userbackInstance) {
          userbackInstance.setData({
            error_message: error.message,
            error_stack: error.stack,
            component_stack: errorInfo.componentStack,
            url: window.location.href,
            timestamp: new Date().toISOString(),
            user_agent: navigator.userAgent,
            environment: process.env.NODE_ENV || 'unknown'
          });
        }
      } catch (userbackError) {
        console.warn('Failed to pre-configure Userback:', userbackError);
      }
    } catch (reportingError) {
      console.error('Failed to report error:', reportingError);
    }
  }
  componentDidUpdate(prevProps: Props) {
    // Reset error state when navigation occurs (if enabled)
    if (this.props.resetOnNavigation && this.state.hasError && (this.props.navigationKey !== prevProps.navigationKey || this.props.children !== prevProps.children)) {
      this.resetErrorBoundary();
    }
  }
  resetErrorBoundary() {
    this.setState({
      hasError: false,
      error: undefined,
      errorInfo: undefined
    });

    // Call onReset callback if provided
    if (this.props.onReset) {
      this.props.onReset();
    }
  }
  render() {
    if (this.state.hasError) {
      // Use custom fallback if provided
      if (this.props.fallback) {
        return typeof this.props.fallback === 'function' ? this.props.fallback({
          error: this.state.error,
          errorInfo: this.state.errorInfo,
          resetErrorBoundary: this.resetErrorBoundary
        }) : this.props.fallback;
      }

      // Otherwise use our default error UI
      return <ErrorUI error={this.state.error} errorInfo={this.state.errorInfo} resetErrorBoundary={this.resetErrorBoundary} data-sentry-element="ErrorUI" data-sentry-component="ErrorBoundary" data-sentry-source-file="error-boundry.tsx" />;
    }
    return this.props.children;
  }
}
export default ErrorBoundary;